.contenedor-studio-introduction{
    width: 100%;
    padding: 30vh 0;
    padding-top: 5vh;
    overflow: hidden;
    @media (max-width: 1023px){
        padding: 5vh 0;
    }
    .main-container{
        flex-direction: column;
        // padding: 0 10vw;
        padding-left: var(--paddingLeft);
        padding-right: var(--paddingRight);
        @media (max-width: 1023px){
            padding: 0;
        }
    }
    .contenedor-img{
        width: 100%;
        height: 80vh;
        margin-bottom: 32px;
        @media (max-width: 1023px){
            height: auto;
        }
        img{
            object-fit: contain;
            object-position: left;
            width: 100%;
            height: 100%;
        }
    }
    h2{
        margin-bottom: 16px;
        &.padding-top{
            margin-top: calc(25vh);
            @media (max-width: 1023px){
                margin-top: 0;
            }
        }
    }
}