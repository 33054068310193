@import './Grafier.scss';
@import './ObjectSans.scss';

/*=====  Tipografia  ======*/
$base-font: 'ObjectSans', sans-serif;
$subase-font: 'GrafierVariable-Display', sans-serif;
$subase-font2: 'GrafierVariable', sans-serif;

h1, .h1{
    font-family: $base-font;
    // font-size: 45px;
    font-size: calc(35px + (55 - 35) * ((100vw - 300px) / (2300 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    @media (max-width: 1023px) {
        line-height: 1.25;
    }
}

h2, .h2{
    font-family: $base-font;
    // font-size: 31.5px;
    font-size: calc(28px + (32 - 28) * ((100vw - 300px) / (2300 - 300)));
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.13px;
    text-rendering: optimizeLegibility;
    @media (max-width: 1023px) {
        line-height: 1.23;
        letter-spacing: normal;
    }
}

h3, .h3{
    font-family: $subase-font2;
    // font-size: 25px;
    font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (2300 - 300)));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    @media (max-width: 1023px) {
        line-height: 1.32;
    }
}

.h3Base{
    font-family: $base-font;
    // font-size: 25px;
    font-size: calc(35px + (35 - 35) * ((100vw - 300px) / (2300 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
}

h4{
    font-family: $base-font;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

p, .p{
    font-family: $subase-font;
    // font-size: 12px;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (2300 - 300)));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    @media (max-width: 1023px){
        line-height: 1.43;
    }
}

.text-loanding{
    font-family: $base-font;
    // font-size: 12px;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (2300 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
}

.text-fixed-buttons{
    font-family: $subase-font;
    // font-size: 7.5px;
    font-size: calc(7.5px + (14 - 7.5) * ((100vw - 300px) / (2300 - 300)));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.82;
    letter-spacing: 6.75px;
    text-rendering: optimizeLegibility;
}
.titulo-general{
    font-family: $subase-font;
    // font-size: 38px;
    font-size: calc(30px + (45 - 30) * ((100vw - 300px) / (2300 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
}
.text-instagram-mobile{
    font-family: $subase-font2;
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (2300 - 300)));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}
.text-input{
    font-family: $subase-font;
    // font-size: 9px;
    font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (2300 - 300)));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    @media (max-width: 1023px){
        line-height: 1.43;
    }
}
.text-form-status{
    font-family: $base-font;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
}
.text-proyects{
    font-family: $base-font;
    // font-size: 10.5px;
    font-size: calc(10.5px + (16 - 10.5) * ((100vw - 300px) / (2300 - 300)));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.1;
    letter-spacing: normal;
}
.text-previousNext{
    font-family: $base-font;
    // font-size: 22.9px;
    font-size: calc(23px + (34 - 23) * ((100vw - 300px) / (2300 - 300)));
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.68;
    letter-spacing: normal;
}