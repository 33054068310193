@import '../../global.scss';

.contenedor-works{
    width: calc(100% - 15vw);
    height: calc((var(--vh, 1vh) * 80) - 16px);
    position: absolute;
    top: calc(20vh + 16px);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    .contenedor-left{
        height: 100%;
        width: calc(15vw + 48px);
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        .item{
            color: $gray;
            padding-bottom: 8px;
            transition: all 300ms ease-in-out;
            text-transform: uppercase;
            &:hover, &.isActive{
                color: $salmon;
            }
        }
    }
    .contenedor-right{
        height: 100%;
        width: auto;
        flex: 1 1 auto;
        position: relative;
        overflow: hidden;
        a{
            &:hover{
                .item-image{
                    filter: grayscale(0);
                    &:before{
                        opacity: 0.5;
                    }
                }
            }
        }
        .swiper-container{
            height: 100%;
        }
        .item-image{
            width: 100%;
            height: 100%;
            background-color: #000;
            position: absolute;
            top: 0;
            left: 0;
            filter: grayscale(100%) brightness(30%) contrast(50%);
            transition: all 300ms ease-in-out;
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #000;
                opacity: 0;
                transition: all 300ms ease-in-out;
            }
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .swiper-slide{
            .contenido{
                position: absolute;
                top: 50%;
                left: calc(15vw + 32px);
                transform: translateY(-50%);
                z-index: 2;
                display: flex;
                flex-direction: column;
                h1{
                    color: $salmon;
                    margin-bottom: 16px;
                }
                h3{
                    color: $white;
                }
            }
        }
    }
}