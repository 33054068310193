@import '../../global.scss';

.contenedor-work{
    width: 100%;
    // .isSticky{
    //     @media (max-width: 1023px){
    //         top: calc(var(--vh, 1vh) * 10) !important;
    //     }
    //     .contenedor-head{
    //         padding-top: 12px;
    //         padding-bottom: 12px;
    //         background-color: #f4f4f4;
    //         h1{
    //             font-size: calc(25px + (45 - 25) * ((100vw - 300px) / (2300 - 300)));
    //         }
    //     }
    // }
    .contenedor-head{
        padding-bottom: 16px;
        position: fixed;
        top: 0;
        left: 7.5vw;
        padding-top: 16px;
        transition: all 300ms ease-in-out;
        width: calc(100% - 7.5vw);
        background-color: $white;
        z-index: 51;
        height: 10vh;
        box-sizing: border-box;
        @media (max-width: 1023px){
            top: calc(var(--vh, 1vh) * 10);
            left: 0;
            width: 100%;
            height: auto;
            z-index: 30;
        }
        .main-container{
            align-items: center;
            box-sizing: border-box;
            padding-left: calc(16px + 29.5vw);
            @media (max-width: 1023px){
                padding-left: 0;
                flex-direction: column;
                align-items: flex-start;
            }
        }
        .contenedor-titulos{
            display: flex;
            flex-direction: column;
            height: 100%;
            @media (max-width: 1023px){
                margin-bottom: 12px;
            }
        }
        .contenido{
            position: absolute;
            right: 7.5vw;
            @media (max-width: 1023px){
                position: static;
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
            .all{
                color: $gray;
                padding-right: 16px;
            }
            .contenido-links{
                .text-loanding{
                    &:nth-child(1){
                        color: $gray;
                        padding-right: 16px;
                    }
                    &:nth-child(2){
                        color: $salmon;
                    }
                }
            }
        }
        h1{
            color: $salmon;
            font-size: calc(25px + (45 - 25) * ((100vw - 300px) / (2300 - 300)));
            // transition: all 300ms ease-in-out;
        }
        h3{
            color: $gray;
        }
    }
    .contenedor-body{
        width: 100%;
        padding-left: 7.5vw;
        box-sizing: border-box;
        height: calc(var(--vh, 1vh) * 90);
        box-sizing: border-box;
        margin-top: 5vh;
        // overflow-y: scroll;
        @media (max-width: 1023px){
            padding-left: 0;
            margin-top: calc(var(--vh, 1vh) * 25);
            height: calc(var(--vh, 1vh) * 75);
        }
        .swiper-container{
            height: 100%;
        }
        .main-container{
            max-width: 100%;
            height: 100%;
            @media (max-width: 1023px){
                max-width: 90%;
                flex-direction: column;
                height: auto;
            }
        }
        .contenedor-list-proyects{
            height: 100%;
            width: calc(15vw + 48px);
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            .item{
                color: $gray;
                padding-bottom: 8px;
                transition: all 300ms ease-in-out;
                text-transform: uppercase;
                &:hover, &.isActive{
                    color: $salmon;
                }
            }
        }
        .contenedor-swiper{
            height: 100%;
            width: 100%;
            // flex: 1 1 auto;
            position: relative;
            overflow: hidden;
        }
        .block{
            width: 100%;
            height: calc(var(--vh, 1vh) * 90);
            box-sizing: border-box;
            display: flex;
            margin-bottom: 16px;
            @media (max-width: 1023px){
                flex-direction: column !important;
                padding: 0 !important;
                height: auto;
                max-height: 100%;
            }
            .item{
                display: flex;
                box-sizing: border-box;
                @media (max-width: 1023px){
                    width: 100% !important;
                    padding: 0 !important;
                    img{
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: contain !important;
                    }
                }
            }
            &.one{
                .item{
                    height: 100%;
                    width: 100%;
                    @media (max-width: 1023px){
                        height: auto;
                        overflow: hidden;
                    }
                    img{
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        // max-height: 100vh;
                        object-position: center;
                        @media (max-width: 1023px){
                            object-fit: cover;
                            height: auto;
                            object-position: unset;
                        }
                    }
                }
            }
            &.two{
                .item{
                    width: 50%;
                    height: 100%;
                    &:nth-child(1){
                        padding-right: 8px;
                        @media (max-width: 1023px){
                            margin-bottom: 16px;
                        }
                    }
                    &:nth-child(2){
                        padding-left: 8px;
                        @media (max-width: 1023px){
                            margin-bottom: 16px;
                        }
                    }
                    img{
                        object-fit: contain;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                        // max-height: 100vh;
                    }
                }
            }
            &.three{
                .item{
                    &:nth-child(1){
                        padding-right: 16px;
                        @media (max-width: 1023px){
                            margin-bottom: 16px;
                        }
                    }
                    &:nth-child(2){
                        padding-right: 16px;
                        @media (max-width: 1023px){
                            margin-bottom: 16px;
                        }
                    }
                    &:nth-child(3){

                    }
                    img{
                        object-fit: contain;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                        // max-height: 100vh;
                    }
                }
            }
            &.final{
                margin-bottom: 0;
                .item{
                    width: 50%;
                    &:nth-child(2){
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        padding-right: 7.5vw;
                        padding-bottom: 15vh;
                        box-sizing: border-box;
                    }
                    img{
                        object-fit: contain;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                        // max-height: 80vh;
                    }
                    .contenido{
                        display: flex;
                        flex-direction: column;
                        .text-previousNext{
                            padding-bottom: 16px;
                            text-align: right;
                            &:first-child{
                                color: $gray;
                                @media (max-width: 1023px){
                                    padding-top: 16px;
                                    padding-bottom: 8px;
                                }
                            }
                            &:last-child{
                                color: $salmon;
                                @media (max-width: 1023px){
                                    padding-top: 8px;
                                    padding-bottom: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}