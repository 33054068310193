@import '../../global.scss';

.modal-menu{
    position: fixed;
    width: 100%;
    height: calc(var(--vh, 1vh) * 90);
    background-color: $white;
    z-index: 40;
    transform: translateY(-100%);
    top: calc(var(--vhResize, 1vh) * 10);
    .main-container{
        flex-direction: column;
    }
    .contenedor-menu-mobile{
        margin-top: calc(var(--vh, 1vh) * 10);
        display: flex;
        flex-direction: column;
        .item{
            padding-bottom: 8px;
            color: $gray;
            display: flex;
            &.isActive{
                color: $salmon;
                div{
                    opacity: 1;
                    width: auto;
                    margin-right: 8px;
                }
            }
            &:last-child{
                padding-bottom: 0;
            }
            div{
                opacity: 0;
                width: 0;
                padding-top: 4px;
                svg{
                    object-fit: contain;
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
    .contenedor-datos{
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        .contenedor-dato{
            display: flex;
            margin-bottom: 12px;
            color: #151515;
            span{
                margin-right: 12px;
                font-weight: bold;
                position: relative;
                // &:before{
                //     content: '';
                //     position: absolute;
                //     width: 4px;
                //     height: 1px;
                //     background-color: $black;
                //     top: -4px;
                //     right: 0;
                // }
            }
        }
    }
}