@import '../../global.scss';


.contenedor-works-mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    .item{
        width: 100%;
        height: 25vh;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background-color: $gray;
        }
        .contenido{
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 12px 5vw;
            box-sizing: border-box;
            h1{
                color: $salmon;
                // margin-bottom: 12px;
            }
            h3{
                color: $white;
            }
        }
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}