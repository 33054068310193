@import '../../global.scss';

.contenedor-team{
    width: 100%;
    position: relative;
    // overflow-x: hidden;
    @media (max-width: 1023px){
        overflow: hidden;
    }
    .video{
        position: fixed;
        width: 100vw;
        height: calc(var(--vhResizeFull, 1vh) * 100);
        z-index: 2;
        top: 0;
        left: 0;
        z-index: 60;
        background-color: white;
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            // filter: grayscale(0%) opacity(1);
            @media (max-width: 1023px){
                object-fit: contain;
            }
        }
        .image-b{
            display: flex;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (max-width: 1023px){
                    object-fit: contain;
                }
            }
        }
        .skip{
            cursor: pointer;
            position: absolute;
            bottom: 32px;
            right: 32px;
            z-index: 2;
            background-color: $gray;
            padding: 12px 16px;
            color: $white;
            border: unset;
            transition: all 300ms ease-in-out;
            &:hover{
                background-color: $salmon;
            }
        }
    }
    .rectangulo{
        position: absolute;
        background-color: #FF8B6C;
        width: 75%;
        height: 25%;
        z-index: -1;
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0);
        @media (max-width: 1023px){
            position: absolute;
            background-color: #FF8B6C;
            width: 100%;
            height: 40%;
            z-index: -1;
            top: 0;
            left: 0;
            transform: translate(0);
        }
    }
    .circle{
        position: absolute;
        background-color: #FF8B6C;
        width: 30vh;
        border-radius: 50%;
        height: 30vh;
        z-index: -1;
        top: 45%;
        left: 54%;
        @media (max-width: 1023px){
            position: absolute;
            background-color: #FF8B6C;
            width: 70vh;
            border-radius: 50%;
            height: 70vh;
            z-index: -1;
            top: 47%;
            left: 26%;
        }
    }
    .contenedor-parallax-img{
        position: absolute;
        opacity: 0.6;
        z-index: -1;
        .swiper-container{
            height: 100%;
        }
        &.one{
            width: 46%;
            top: -6%;
            left: 50%;
            @media (max-width: 1023px){
                width: 29%;
                top: 50%;
                left: 0;
                height: 40vh;
            }
            img{
                object-fit: cover;
                object-position: bottom;
                width: 100%;
                height: 100%;
            }
        }
        &.two{
            width: 46%;
            top: 20%;
            @media (max-width: 1023px){
                width: 82%;
                height: 45vh;
                top: 71%;
                right: 0;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.three{
            width: 45%;
            top: 72%;
            left: 40%;
            @media (max-width: 1023px){
                display: none;
            }
        }
        img{
            object-fit: contain;
            width: 100%;
        }
    }
    .main-container{
        flex-direction: column;
        padding: 0 10vw;
        padding-left: var(--paddingLeft);
        @media (max-width: 1023px){
            padding: 0;
        }
    }
    .member{
        width: 100%;
        display: flex;
        margin-bottom: 64px;
        @media (max-width: 1023px){
            flex-direction: column;
        }
        .contenedor-img{
            width: 30%;
            @media (max-width: 1023px){
                width: 100%;
            }
            img{
                object-fit: contain;
                width: 100%;
            }
        }
        h1{
            width: 40%;
            padding: 0 5%;
            box-sizing: border-box;
            color: $gray;
            @media (max-width: 1023px){
                width: 100%;
                padding: 32px 0;
            }
        }
        p{
            width: 30%;
            color: $gray;
            @media (max-width: 1023px){
                width: 100%;
            }
        }
    }
}