@import '../../global.scss';

.contenedor-contact{
    background-color: $salmon;
    width: 100%;
    margin-top: 20vh;
    padding-bottom: 20vh;
    position: relative;
    @media (max-width: 1023px){
        padding-bottom: 10vh;
    }
    .box{
        width: 100vw;
        height: 35vh;
        position: absolute;
        pointer-events: none;
        bottom: 100%;
        left: 0;
        overflow: hidden;
        z-index: 60;
        pointer-events: none;
        .carita{
            position: absolute;
            bottom: 0;
            &.sonriente{
                transform: translate(143%, 27%);
                width: 25vh;
                z-index: 40;
                @media (max-width: 1023px){
                    transform: translate(257%, -155%);
                    width: 13vh;
                    z-index: 40;
                }
            }
            &.giñando{
                transform: translate(-120%, -63%);
                width: 15vh;
                z-index: 50;
                left: 50%;
                @media (max-width: 1023px){
                    transform: translate(110%, 25%);
                    width: 13vh;
                    z-index: 50;
                    left: 50%;
                }
                
            }
            &.alegre{
                transform: translate(70%, -132%);
                width: 10vh;
                z-index: 50;
                left: 70%;
                @media (max-width: 1023px){
                    display: none;
                }
            }
            img{
                object-fit: contain;
                width: 100%;
            }
        }
    }

    .main-container{
        @media (max-width: 1023px){
            flex-direction: column-reverse;
        }
    }
    .contenedor-left{
        width: 40%;
        padding-right: 5%;
        box-sizing: border-box;
        @media (max-width: 1023px){
            width: 100%;
            padding: 0;
        }
        p{
            color: #151515;
            display: flex;
            padding: 10vh 0;
            align-items: flex-end;
            flex-direction: column;
            @media (max-width: 1023px){
                justify-content: flex-start;
                padding: 0;
                margin-bottom: 32px;
            }
            span{
                width: 40%;
                &:first-child{
                    margin-bottom: 16px;
                }
                @media (max-width: 1023px){
                    width: 100%;
                    &:first-child{
                        margin-bottom: 0;
                    }
                }
            }

        }
        a{
            color: #151515;
            @media (max-width: 1023px){
                margin-bottom: 32px;
                display: flex;
            }

        }
        form{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .input{
                margin-bottom: 16px;
                display: flex;
                width: 100%;

                input, textarea{
                    width: 100%;
                    background-color: #f4f4f4;
                    box-shadow: unset;
                    border: unset;
                    padding: 8px;
                    padding-bottom: unset;
                    border-bottom: transparent;
                    outline: unset;
                    border-radius: 0;
                    @media (max-width: 1023px){
                        padding: 12px;
                    }
                    &:active, &:focus{
                        border-bottom: 1px solid $black;
                    }
                    &::placeholder{
                        color: #c4c7cc;
                    }
                }
                textarea{
                    height: 20vh;
                    resize: none;
                }
            }
        }
        .contenedor-mensajes-padre{
            width: 100%;
            position: relative;
            padding: 32px 0;
            .contenedor-img{
                width: 48px;
                margin-bottom: 16px;
                img{
                    object-fit: contain;
                    width: 100%;
                }
            }
            .contenedor-mensajes{
                color: $black;
            }
        }
    }
    .contenedor-right{
        width: 60%;
        position: relative;
        padding-top: 32px;
        @media (max-width: 1023px){
            width: 100%;
        }
        h1{
            top: 0;
            transform: translateY(-100%);
            color: $gray;
            position: absolute;   
        }
        h2{
            margin-bottom: 32px;
            color: #f4f4f4;
        }
        p{
            width: 60%;
            @media (max-width: 1023px){
                width: 100%;
            }
        }
    }
}