@import '../../global.scss';

.btn-main{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray;
    padding: 12px 48px;
    border: unset;
    color: #f4f4f4;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    outline: unset;
    &:hover{
        background-color: $black;
    }
    &.disable{
        pointer-events: none;
    }
}