@import '../../global.scss';

.header-mobile{
    width: 100%;
    .nav-mobile{
        .contenedor-top{
            position: fixed;
            top: 0;
            left: 0;
            padding: 0 5vw;
            z-index: 50;
            justify-content: space-between;
            align-items: center;
            max-width: 100% !important;
            height: calc(var(--vhResize, 1vh) * 10);
            background-color: $white;
            .contenedor-logo{
                height: 100%;
                width: 40%;
                position: relative;
                transform-origin: left;
                z-index: 3;
                svg{
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                }
                .contenedor-linea{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 130%;
                    height: 100%;
                    svg{
                        object-fit: contain;
                        width: 100%;
                    }
                }
            }
            .contenedor-right{
                display: flex;
                align-items: center;
                .contenedor-insta{
                    display: flex;
                    align-items: center;
                    color: #151515;
                    .line{
                        content: '';
                        width: 16px;
                        height: 1px;
                        background-color: $gray;
                        margin-right: 12px;
                    }
                }
                .burger-menu{
                    cursor: pointer;
                    width: calc(var(--vhResize, 1vh) * 10);
                    height: calc(var(--vhResize, 1vh) * 10);
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    box-sizing: border-box;
                    background-color: $gray;
                    position: relative;
                    .line{
                        position:  absolute;
                        height: 1px;
                        width: calc(100% - 24px);
                        background-color: $white;
                        left: 50%;
                        transform: translate(-50%, 0);
                        &:first-child{
                            top: 16px;
                        }
                        &:nth-child(2){
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                        &:last-child{
                            top: calc(100% - 16px);
                        }
                    }
                }
            }
        }
        .contenedor-bottom{
            height: calc(var(--vhResize, 1vh) * 20);
            margin-top: calc(var(--vhResize, 1vh) * 10);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            h3{
                color: $gray;
            }
            .contenedor-titulo{
                color: $salmon;
            }
        }
    }
}