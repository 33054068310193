@import '../../global.scss';

.contenedor-404{
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-color: $white;
    .main-container{
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .contenido{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
            width: 50%;
            margin-bottom: 32px;
            @media (max-width: 1023px){
                width: 80%;
            }
        }
        h2{
            margin-bottom: 32px;
            text-align: center;
        }
    }
}