@import '../../global.scss';

.contenedor-background{
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
}