@import '../../global.scss';

.contenedor-instagram-section{
    width: 100%;
    margin-top: 16px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: calc(100% - ((85vw * 0.19) / 2));
        width: 100%;
        background-color: $salmon;
        z-index: -1;
    }
    .main-container{
        flex-wrap: wrap;
    }
    .item{
        width: 19%;
        height: calc(85vw * 0.19);
        margin-right: calc((100% * 0.05) / 4);
        margin-bottom: calc((100% * 0.05) / 4);
        background-color: $salmon;
        overflow: hidden;
        &:nth-child(5n + 5){
            margin-right: 0;
        }
        @media (max-width: 1023px){
            width: 24%;
            height: calc(90vw * 0.24);
            margin-right: calc((100% * 0.04) / 3);
            margin-bottom: calc((100% * 0.04) / 3);
            &:nth-child(5n + 5){
                margin-right: calc((100% * 0.04) / 3);
            }
            &:nth-child(4n + 4){
                margin-right: 0;
            }
        }
        @media (max-width: 795px){
            width: 32%;
            height: calc(90vw * 0.32);
            margin-right: calc((100% * 0.04) / 2);
            margin-bottom: calc((100% * 0.04) / 2);
            &:nth-child(5n + 5){
                margin-right: calc((100% * 0.04) / 2);
            }
            &:nth-child(4n + 4){
                margin-right: calc((100% * 0.04) / 2);
            }
            &:nth-child(3n + 3){
                margin-right: 0;
            }
        }
        @media (max-width: 699px){
            width: 49%;
            height: calc(90vw * 0.49);
            margin-right: calc(100% * 0.02);
            margin-bottom: calc(100% * 0.02);
            &:nth-child(5n + 5){
                margin-right: calc(100% * 0.02);
            }
            &:nth-child(4n + 4){
                margin-right: calc(100% * 0.02);
            }
            &:nth-child(3n + 3){
                margin-right: calc(100% * 0.02);
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }
        }
        img, video{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .item-children-padre{
            display: flex;
            height: 100%;
        }
        .item-children{
            width: 100%;
            height: 100%;
        }
    }
}