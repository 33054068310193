@import '../../global.scss';

.contenedor-cta-contact{
    width: 100%;
    // background-color: $white;
    padding: 10vh 0;
    .main-container{
        display: flex;
        flex-direction: column;
        padding: 0 10vw;
        padding-left: var(--paddingLeft);
        @media (max-width: 1023px){
            padding: 0;
        }
    }
    h2{
        margin-bottom: 16px;
    }
    .h3Base{
        color: $salmon;
    }
}