@import '../../global.scss';

.contenedor-menu{
    width: 100%;
    height: 0;
    padding-left: 15vw;
    padding-top: 5vh;
    box-sizing: border-box;
    position: relative;
    background-color: $white;
    z-index: 50;
    .burger-menu{
        cursor: pointer;
        width: 32px;
        height: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 6px;
        box-sizing: border-box;
        background-color: $gray;
        position: absolute;
        top: 5vh;
        left: calc(15vw);
        transform: translate(50%, 0);
        transition: all 400ms ease-in-out;
        z-index: 2;
        overflow: hidden;
        // &:hover{
        //     background-color: $salmon;
        // }
        &.left{
            left: calc(-5vw);
            transform: translate(0, 0);
        }
        .line{
            height: 1px;
            width: 100%;
            background-color: $white;
            z-index: 3;
        }
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $salmon;
            opacity: 0;
            z-index: 2;
            transition: all 300ms ease-in-out;
        }
        &:hover{
            &:before{
                opacity: 1;
            }
        }
    }
    .contenedor-titulo{
        position: absolute;
        top: calc(15vh + 16px);
        right: 0;
        color: $salmon;
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        span{
            &:last-child{
                width: 0;
                overflow: hidden;
                display: inline-flex;
                white-space: nowrap;
            }
        }
    }
    .contenedor-menu-child{
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: calc(16px + 7vw);
        box-sizing: border-box;
        opacity: 0;
        .item{
            font-weight: bold;
            color: $gray;
            display: flex;
            transition: all 400ms ease-in-out;
            position: relative;
            transform: translateX(-50px);
            &:hover, &.isActive{
                transform: translateX(0);
                span{
                    color: $salmon;
                }
                div{
                    opacity: 1;
                }
            }
            span{
                transition: all 400ms ease-in-out;
            }
            div{
                opacity: 0;
                padding-top: 4px;
                margin-right: 12px;
                transition: all 400ms ease-in-out;
                svg{
                    object-fit: contain;
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
}