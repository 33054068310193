@import '../../global.scss';

.loading{
    position: fixed;
    z-index: -2;
    width: 100vw;
    height: 100vh;
    background-color: $gray;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    &.isLoading{
        z-index: 90;
        opacity: 1;
    }
    .spinner{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid $white;
        position: relative;
        animation: spinner 3s infinite linear;
        .forma{
            width: 10px;
            height: 10px;
        }
        .circle{
            position: absolute;
            background-color: $white;
            border-radius: 50%;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
        }
        .square{
            position: absolute;
            background-color: $white;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
        }
        .triangle{
            position: absolute;
            background-color: $white;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
        .diamond{
            position: absolute;
            background-color: $white;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }
        @keyframes spinner{
            from{
                transform: rotate(360deg);
            }
            to{
                transform: rotate(0);
            }
        }
    }
    .text-loanding{
        position: absolute;
        color: white;
    }
}