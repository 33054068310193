@import 'assets/fonts/fonts.scss';

@-moz-document url-prefix() { /* Disable scrollbar Firefox */
  html{
    scrollbar-width: none;
  }
}
#root{
  opacity: 0;
}
html{
  overflow-x: hidden;
  height: -webkit-fill-available;
}
body {
  background-color: #f4f4f4;
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none;  /* Disable scrollbar IE 10+ */
  // overflow-y: scroll;
  // overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}

h1, h2, h3, p{
  margin: 0;
}

a{
  text-decoration: none;
}

.main-container{
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    @media (max-width: 1023px) {
      max-width: 90%;
    }
}

  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 40px #f4f4f4 inset !important;
      background-color: transparent !important;
  }
  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: #3A3A3A !important;
    background-color: transparent !important;
  }