@import '../../global.scss';

header{
    width: 100%;
    nav{
        height: 10vh;
        padding-top: 5vh;
        display: flex;
        justify-content: space-between;
        padding-right: 7.5vw;
        padding-left: 4vw;
        .contenedor-logo{
            height: 100%;
            width: 45%;
            position: relative;
            transform-origin: left;
            z-index: 60;
            svg, img{
                object-fit: contain;
                height: 100%;
                width: auto;
            }
            .contenedor-linea{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                svg{
                    object-fit: contain;
                    width: 100%;
                }
            }
        }
        .contenedor-right{
            width: 55%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            z-index: 60;
            .contenedor-telefono, .contenedor-correo{
                display: flex;
                height: 100%;
                align-items: flex-end;
                &:hover{
                    p{
                        .hide{  
                            opacity: 0 !important;
                            transform: translateX(32px) !important;
                        }
                        .show{
                            opacity: 1 !important;
                            transform: translateX(0) !important;
                        }
                    }
                }
                p{
                    color: $black;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        width: 16px;
                        height: 1px;
                        background-color: $black;
                        bottom: calc(100% + 8px);
                        right: 0;
                    }
                    span{
                        transition: all 400ms ease-in-out;
                    }
                    .hide{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                    .show{
                        opacity: 0;
                        transform: translateX(32px);
                    }
                }
            }
            // .contenedor-telefono{

            // }
            // .contenedor-correo{
  
            // }
            .contenedor-text-right{
                text-align: right;
                p{
                    color: $black;
                }
            }
        }
    }
    .contenedor-instagram{
        height: 7.5vw;
        width: 85vh;
        padding-left: 15vh;
        box-sizing: border-box;
        position: fixed;
        left: 0;
        transform: rotate(-90deg) translate(0%, 100%);
        transform-origin: 0 100%;
        bottom: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        z-index: 40;
        &:hover{
            .text-fixed-buttons{
                &:before{
                    transform: translateY(-50%) translateX(0);
                }
                &:after{
                    transform: translateX(0);
                }
            }
        }
        .text-fixed-buttons{
            position: relative;
            padding: 0 calc(12vh - 32px);
            margin-left: 32px;
            box-sizing: border-box;
            color: $gray;
            &:before, &:after{
                position: absolute;
                background-color: $gray;
                content: '';
                height: 1px;
                width: 32px;
                top: 50%;
                transition: all 300ms ease-in-out;
            }
            &:before{
                transform: translateY(-50%) translateX(calc(12vh - 48px));
                right: 100%;
            }
            &:after{
                transform: translateX(calc((12vh - 48px) * -1));
                left: 100%;
            }
        }
    }
    .contenedor-since{
        height: 7.5vw;
        width: 85vh;
        padding-left: 15vh;
        box-sizing: border-box;
        position: fixed;
        left: 92.5%;
        transform: rotate(-90deg) translate(0%, 100%);
        transform-origin: 0 100%;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 40;
        .text-fixed-buttons{
            box-sizing: border-box;
            width: 100%;
            position: relative;
            color: $gray;
        }
    }
}