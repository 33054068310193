/* ObjectSans - bold */
@font-face {
    font-family: 'ObjectSans';
    font-style: normal;
    font-weight: bold;
    src: url('./ObjectSans/ObjectSans-Bold.eot'); /* IE9 Compat Modes */
    src: local('ObjectSans'), local('ObjectSans'),
         url('./ObjectSans/ObjectSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        //  url('./ObjectSans/ObjectSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('./ObjectSans/ObjectSans-Bold.woff') format('woff'), /* Modern Browsers */
         url('./ObjectSans/ObjectSans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./ObjectSans/ObjectSans-Bold.svg#ObjectSans') format('svg'); /* Legacy iOS */
}

/* ObjectSans - thin */
@font-face {
    font-family: 'ObjectSans';
    font-style: normal;
    font-weight: 100;
    src: url('./ObjectSans/ObjectSans-Thin.eot'); /* IE9 Compat Modes */
    src: local('ObjectSans'), local('ObjectSans'),
         url('./ObjectSans/ObjectSans-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        //  url('./ObjectSans/ObjectSans-Thin.woff2') format('woff2'), /* Super Modern Browsers */
         url('./ObjectSans/ObjectSans-Thin.woff') format('woff'), /* Modern Browsers */
         url('./ObjectSans/ObjectSans-Thin.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./ObjectSans/ObjectSans-Thin.svg#ObjectSans') format('svg'); /* Legacy iOS */
}