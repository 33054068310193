/* Grafier - regularDisplay */
@font-face {
    font-family: 'GrafierVariable-Display';
    font-style: normal;
    font-weight: normal;
    src: url('./Grafier/Grafier-RegularDisplay.eot'); /* IE9 Compat Modes */
    src: local('GrafierVariable-Display'), local('GrafierVariable-Display'),
         url('./Grafier/Grafier-RegularDisplay.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        //  url('./Grafier/Grafier-RegularDisplay.woff2') format('woff2'), /* Super Modern Browsers */
         url('./Grafier/Grafier-RegularDisplay.woff') format('woff'), /* Modern Browsers */
         url('./Grafier/Grafier-RegularDisplay.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./Grafier/Grafier-RegularDisplay.svg#Grafier-RegularDisplay') format('svg'); /* Legacy iOS */
}

/* Grafier - variable */
@font-face {
    font-family: 'GrafierVariable';
    font-style: normal;
    font-weight: normal;
    src: url('./Grafier/Grafier-Variable.eot'); /* IE9 Compat Modes */
    src: local('GrafierVariable'), local('GrafierVariable'),
         url('./Grafier/Grafier-Variable.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        //  url('./Grafier/Grafier-Variable.woff2') format('woff2'), /* Super Modern Browsers */
         url('./Grafier/Grafier-Variable.woff') format('woff'), /* Modern Browsers */
         url('./Grafier/Grafier-Variable.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./Grafier/Grafier-Variable.svg#GrafierVariable') format('svg'); /* Legacy iOS */
}